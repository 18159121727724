import 'assets/scss/components/system.scss';
import 'assets/scss/utils/_color.scss';
import logo from 'assets/images/logo.svg';
import pricelist from 'assets/images/system/pricelist.png';
import timePriceStandard from 'assets/images/system/timeprice-standard.png';
import timePriceGroup from 'assets/images/system/timeprice-group-2.png';
import timePriceVip from 'assets/images/system/timeprice-vip.png';
import mv1Mb from 'assets/images/system/mv1-mb.png';
import mv2Mb from 'assets/images/system/mv2-mb.png';
import mv2Pc from 'assets/images/system/mv2-pc.png';
import drink from 'assets/images/system/drink-image.png';
import benefit from 'assets/images/system/benefit-image.png';

const System = () => {
    return (
        <>
            <section className="section system">
                <div className="section--container">
                    <p className="system--logo fadeload">
                        <img src={logo} alt="SERENA" loading='lazy' />
                    </p>
                    <h3 className='fadeload'>SYSTEM</h3>
                    <p className='pricelist fadeload'>
                        <img src={pricelist} alt="入会金無料, 月会費無料, 初回カウンセリング20分無料, 初回体験 50分5,000円, 単発1回12,000円" loading='eager' />
                    </p>

                    <div className='timeprices'>
                        <div className='pc fadeload'>
                            <img src={timePriceStandard} alt="STANDARD 単発 12,000円, 4回 44,000円, 8回 84,000円" loading='eager' />
                            <img src={timePriceGroup} alt="GROUP 単発 33,000円, 4回 128,000円, 8回 240,000円, 16回 448,000円" loading='eager' />
                            <img src={timePriceVip} alt="VIP 通い放題 120,000円" loading='eager' />
                        </div>
                        <div className='mb'>
                            <p className='timeprice timeprice__main fadeload'><img src={timePriceStandard} alt="STANDARD 単発 12,000円, 4回 44,000円, 8回 84,000円" loading='eager' /></p>
                            <p className='mv mv1 fadeload'><img src={mv1Mb} alt='イメージ' loading='eager' /></p>
                            <div className='timeprice timeprice__online'>
                                <h4 className='fadeload'>GROUP</h4>
                                <p className='fadeload'><img src={timePriceGroup} alt="GROUP 単発 33,000円, 4回 128,000円, 8回 240,000円, 16回 448,000円" loading='eager' /></p>
                            </div>
                            <p className='mv mv2 fadeload'><img src={mv2Mb} alt="イメージ" loading='eager' /></p>
                            <div className='timeprice timeprice__group'>
                                <h4 className='fadeload'>VIP</h4>
                                <p className='fadeload'><img src={timePriceVip} alt="VIP 通い放題 120,000円" loading='eager' /></p>
                            </div>
                        </div>
                    </div>
                    <div className='change-and-cancel fadeload'>
                        <p>■ご予約の振替とキャンセル<br />
                        予約⽇前⽇23:59までに連絡頂いた場合、何度でも振替可能。<br />
                        予約⽇当日の振替又はキャンセルにつきましては1回分消費</p>
                    </div>
                    <p className='visual fadeload'>
                        <img src={mv2Pc} alt="イメージ" loading='eager' />
                    </p>

                    <div className='other'>
                        <h3 className='menu-title fadeload'>-OTHER-</h3>
                        <div className='other-list fadeload'>
                            <dl>
                                <dt>水</dt>
                                <dd>100円</dd>
                            </dl>
                            <dl>
                                <dt>プロテイン（各種）</dt>
                                <dd>500円</dd>
                            </dl>
                            <dl>
                                <dt>サプリ（セット）</dt>
                                <dd>100円</dd>
                            </dl>
                            <dl>
                                <dt>レンタルウェア</dt>
                                <dd>FREE</dd>
                            </dl>
                            <dl>
                                <dt>レンタルシューズ</dt>
                                <dd>FREE</dd>
                            </dl>
                        </div>
                    </div>

                    <div className='benefit'>
                        <p className='benefit--image fadeload'><img src={benefit} alt="お友達照会特典" loading='eager' /></p>
                        <div className='benefit--text fadeload'>
                            <p>会員様の中からお客様をご紹介いただくと一回分をプレゼントいたします。<br />
                            この機会にお友達皆様で目標達成に向かいましょう！</p>
                        </div>
                    </div>

                    <p className='first fadeload'>まずはお気軽に<span className='ft-yellow'>ご体験</span>ください!</p>
                </div>
            </section>
        </>
    )
};

export default System;